<template>
  <div class="merchants-page">
    <div class="merchants-introduce">
      <img class="image" src="@/assets/shangjia.png" />
      <p>
        {{ $t("merchants.title") }}
        <br />
        <span v-html="$t('merchants.title1')"></span>
      </p>
      <h3 class="production">
        {{ $t("merchants.pin") }}({{ merchantsList.length }})
      </h3>
    </div>
    <div class="merchants-list">
      <el-row :gutter="20">
        <el-col v-for="item in merchantsList" :key="item.id" :span="6">
          <el-card :body-style="{ padding: '0px' }">
            <el-image
              @click="gotoDetail(item)"
              fit="cover"
              :src="item.coverImage"
              class="image"
              lazy
            />
            <div class="card-description">
              <div class="left">
                <img class="images" width="40px" src="@/assets/shangjia.png" />
                <span>{{ $i18n.locale === "zh" ? item.title : item.titleEn | replaceTitle }}</span>
              </div>
              <div class="right">
                <span class="watch">
                  <i class="el-icon-view" />
                  <span>{{ item.view }}</span>
                </span>
                <collect
                  class="collect"
                  :item="item"
                  @confirmCollect="confirmCollect(item)"
                />
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <!-- <el-pagination class="pagination" background layout="prev, pager, next,jumper" :total="1000" /> -->
    </div>
  </div>
</template>
<script>
import { materialsList, checkMaterial } from "@/api/home";
import collect from "@/components/collect";
import { getCollect } from "@/api/collect";
export default {
  // 商家页面
  name: "MerchantsPage",
  components: {
    collect,
  },
  data() {
    return {
      merchantsList: [],
    };
  },
  created() {
    this.materialsList();
  },
  mounted() {},
  methods: {
    //查看详情
    async gotoDetail(item) {
      await checkMaterial(item.id);
      this.$router.push({ path: `/detail/${item.id}`, query: item });
    },
    //获取列表
    async materialsList() {
      const {
        data: { data },
      } = await materialsList();
      const arr = [data[0],data[1],data[4]]
      this.merchantsList = arr.map((item) => {
        item.collect = false;
        return item;
      });

      if (this.$store.state.token) {
        const { data: collect } = await getCollect(); // 获取收藏列表进行筛选
        if (collect.data !== []) {
          collect.data.forEach((item) => {
            this.merchantsList.forEach((key) => {
              if (key.id == item.materialId) {
                key.collect = true;
                return;
              }
            });
          });
        }
      }
    },
    //确认收藏
    confirmCollect(item) {
      if (item.collect) {
        item.collect = false;
        item.star--;
      } else {
        item.collect = true;
        item.star++;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.merchants-page {
  background-image: linear-gradient(to right, #6e2934, #2d0f23);
  overflow: hidden;
  .merchants-introduce {
    position: relative;
    width: 1441px;
    height: 316px;
    margin: 110px auto 20px;
    background-color: #fff;
    text-align: center;
    padding-top: 30px;
    box-sizing: border-box;
    border-radius: 10px;
    .image {
      width: 120px;
      height: 120px;
    }

    .production {
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
      margin: auto;
      width: 80px;
      border-bottom: 3px solid #ffc322;
    }
  }
  .merchants-list {
    width: 1410px;
    margin: auto;
    padding: 0 20px;
    .el-row {
      .el-col {
        margin-bottom: 20px !important;
        .el-card {
          border: none;
          height: 250px;
        }
        .image {
          cursor: pointer;
          width: 100%;
          height: 200px;
          display: block;
        }
        .images {
          vertical-align: middle;
          // margin-right: 3px;
          transform: translateY(-2px);
        }
        .card-description {
          height: 50px;
          line-height: 50px;
          padding: 0 5px;
          .left {
            float: left;
            .el-avatar {
              margin-right: 5px;
              vertical-align: middle;
            }
          }
          .right {
            float: right;
            box-sizing: border-box;
            .el-icon-view {
              margin-right: 1px;
              font-size: 18px;
              vertical-align: middle;
            }
            .watch {
              // margin-right: 10px;
              .el-icon-view {
                transform: translateY(-1px);
                margin-right: 2px;
              }
            }
          }
        }
        span {
          font-size: 14px !important;
        }
      }
    }
    .pagination {
      padding: 50px 0 80px 0;
      text-align: center;
      font-size: 14px;
    }
  }
}
</style>
// 分页组件css
<style lang="scss">
.pagination {
  .el-pagination__jump {
    color: #fff;
    font-size: 20px !important;
    vertical-align: middle;
    .is-in-pagination {
      font-size: 20px;
      width: 68px;
      height: 40px;
      .el-input__inner {
        height: 40px;
      }
    }
  }
  .el-pager {
    .number,
    .more {
      color: #fff !important;
      background-color: rgba(0, 0, 0, 0) !important;
      line-height: 40px;
      font-size: 20px;
      font-weight: 400;
    }
    .active {
      background-color: #fff !important;
      color: #000 !important;
      width: 40px;
      height: 40px;
      border-radius: 50% !important;
    }
  }
  .btn-prev,
  .btn-next {
    color: #000 !important;
    width: 40px;
    height: 40px;
    margin: 0 40px !important;
    .el-icon {
      font-size: 20px;
    }
  }
}
</style>
